import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Search from "../components/Search/Search"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />

    <div className="grid">
      <div className="grid__sidebar">
        <h2 style={{ marginTop: "1.1em" }} className="visually-hidden--sml">
          Search
        </h2>
        <Search />
      </div>
      <div className="grid__main">
        <h1>Error 404</h1>
        <h2>Page not found</h2>
        <p>We did not find the page you’re looking for.</p>
        <p>It’s probably best if you search our site.</p>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
